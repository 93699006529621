var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main-container',{attrs:{"icon":"mdi-account-group-outline","title":"Turmas"},scopedSlots:_vm._u([{key:"tools",fn:function(){return [(!_vm.isAnima())?_c('emc-excel-import-icon',{attrs:{"tool-tip":"Importar Turmas","param":"groups"},on:{"onCompleted":function($event){return _vm.getData()}}}):_vm._e(),(!_vm.isAnima())?_c('emc-excel-import-icon',{attrs:{"tool-tip":"Importar Disciplinas das Turmas","param":"group_disciplines","color":"purple"},on:{"onCompleted":function($event){return _vm.getData()}}}):_vm._e(),_c('emc-excel-import-icon',{attrs:{"tool-tip":"Importar SubTurmas","param":"sub_groups","color":"primary"},on:{"onCompleted":function($event){return _vm.getData()}}}),_c('s-icon-report-download',{attrs:{"report":"9","show-message":""}}),(!_vm.isAnima())?_c('emc-excel-export-icon',{attrs:{"color":"purple","tool-tip":"Sub Turmas","report-id":"49"}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"orange darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(39)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v(" Relatório de Disciplinas das Turmas ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.download(24)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_c('span',[_vm._v(" Relatório de Turmas e Ingressantes ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo darken-1","icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.showForm = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(" Cadastrar Nova Turma ")])])]},proxy:true}])},[_c('emc-loader-progress',{attrs:{"show":_vm.loadingProgress}}),_c('emc-table-items',{attrs:{"headers":_vm.headers,"params":_vm.params,"collections":_vm.collections,"meta":_vm.meta,"itemDelete":_vm.itemDelete,"itemEdit":_vm.model,"loading":_vm.loading,"find":true,"searchLength":2,"label":"Pesquisar por campus, curso ou turma..."},on:{"update:params":function($event){_vm.params=$event},"update:collections":function($event){_vm.collections=$event},"update:meta":function($event){_vm.meta=$event},"update:itemDelete":function($event){_vm.itemDelete=$event},"update:item-delete":function($event){_vm.itemDelete=$event},"update:itemEdit":function($event){_vm.model=$event},"update:item-edit":function($event){_vm.model=$event}}}),_c('sys-register-group',{attrs:{"model":_vm.model,"show":_vm.showForm},on:{"onCompleted":function($event){return _vm.getData()},"update:show":function($event){_vm.showForm=$event}}}),_c('emc-alert-modal',{attrs:{"text":_vm.textDelete,"disabled":_vm.deleting,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting,"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])}),_c('emc-alert-snackbar',{attrs:{"show":!! _vm.message.length,"message":_vm.message,"color":_vm.error ? 'error' : 'success'}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }